/* Z�kladn� layout */

.formular-popiska {padding-bottom:15px;}
.forms-radek>td {padding-bottom:15px;}
.forms-levy {width:120px; text-align:right; padding-right:15px; vertical-align:top;}
.forms-table {border-spacing:0px;}
.forms-sloupce, .forms-sloupce table {border-spacing:0px;}
.forms-sloupce>tbody>tr>td, .forms-sloupce>tr>td {vertical-align:top;}
.forms-nadpis-formulare {font-weight:bold; font-size:115%;}
.forms-siroky .forms-popis {padding-bottom:10px;}
.forms-tr-hidden td {height:0px; padding:0px;}
.forms-position-wrapper {position:relative;}


/* Validace - bubliny */
.formsInputIndicator {width:16px; height:16px; margin:2px 0px 0px 5px; vertical-align:top;}
.formsInputIndicator img {width:16px; height:16px;}
.forms-invalid-popup {background:red; background-color:rgba(217, 0, 0, 0.85); color:white; padding:8px 14px 8px 14px; font-size:12px; border-radius:5px; text-shadow:rgba(0,0,0,0.4) 0px 1px 0px; max-width:230px; z-index:102;}
.forms-warning-popup {background:#F2DB4B; color:black; padding:8px 20px 8px 20px; font-size:12px; border-radius:5px; text-shadow:rgba(255,255,255,0.6) 0px 1px 0px; max-width:200px; z-index:101;}
.forms-invalid-popup .forms-popup-zobacek, .forms-warning-popup .forms-popup-zobacek {background: no-repeat left top; width:9px; height:9px; display:block; position:absolute;}
.forms-popup-validace-bottom .forms-popup-zobacek {left:20px; top:-9px; background-image:url("./zobacek-cerveny-nahoru.png");}
.forms-popup-validace-right .forms-popup-zobacek {left:-9px; top:6px; background-image:url("./zobacek-cerveny-doleva.png");}
.forms-popup-validace-top .forms-popup-zobacek {left:20px; bottom:-9px; background-image:url("./zobacek-cerveny-dolu.png");}
.forms-popup-validace-bottom.forms-warning-popup .forms-popup-zobacek {background-image:url("./zobacek-zluty-nahoru.png");}
.forms-popup-validace-right.forms-warning-popup .forms-popup-zobacek {background-image:url("./zobacek-zluty-doleva.png");}
.forms-popup-validace-top.forms-warning-popup .forms-popup-zobacek {background-image:url("./zobacek-zluty-dolu.png");}
.formsCheckboxIndicator {width:16px; height:16px;  margin:2px 5px 0px 5px;}
.forms-prvek select {vertical-align:middle;}
.formsSelectIndicator {width:16px; height:16px;  margin:2px 5px 0px 5px; vertical-align:middle;}
.forms-position-wrapper .formsInputIndicator {margin-top:0px;}

/* Validace - ostatn� */
.forms-invalid-input {border-color:#D90000;}
.forms-error {background:#D90000; margin:5px 0px 10px 0px; color:white; padding:8px 20px 8px 20px; font-size:12px; border-radius:5px; text-shadow:rgba(0,0,0,0.6) 0px 1px 0px; max-width:200px;}


/* Povinn�-info */
.forms-info-povinne {padding-bottom:10px; padding-top:10px;}

/* Textarea */
.forms-input, .forms-textarea {font-family:Arial, sans-serif; font-size:12px; font-weight:normal;}
.forms-textarea {height:100px; font-family:Arial, sans-serif; font-size:12px;}
form .forms-table *::-moz-placeholder {
	color: black;
}
form .forms-table *::-webkit-placeholder, form .forms-table *::-webkit-input-placeholder {
	color: black;
}
form .forms-table *::-ms-placeholder, form .forms-table *::-ms-input-placeholder {
	color: black;
}
form .forms-table *::placeholder {
	color: black;
}


/* Multibox */
.formsCheckboxTable {border-spacing:0px;}
.formsCheckboxDiv  {position:relative; }
.formsCheckboxTableNadpis {padding-bottom:4px; padding-top:10px;}
.formsCheckboxTd {vertical-align:top;}
.formsCheckboxTdLabel {padding-top:0px; vertical-align:top; line-height:inherit;}
.forms-checkbox-orig-overlaid {opacity:0.01;}
tr:first-child .formsCheckboxTableNadpis {padding-top:0px;}
tr:last-child .formsCheckboxTableNadpis {padding-bottom:0px;}

/* Slider */
.ui-slider {float:left; position:relative;}
.ui-slider a {display:block; position:absolute;}
.forms-slider-pole {font-size:12px; min-width:60px; max-width:140px; float:left; text-align:center; padding:0px;}
.forms-slider-pole-l {text-align:right; padding-right:8px;}
.forms-slider-pole-r {text-align:left; padding-left:8px;}
.forms-slider-full {padding-right:75px; position:relative;}
.forms-slider-full.forms-slider-range {padding-left:75px;}
.forms-slider-full .ui-slider {float:none;}
.forms-slider-full .forms-slider-pole {position:absolute; top:-4px;}
.forms-slider-full .forms-slider-pole.forms-slider-pole-l {left:0px;}
.forms-slider-full .forms-slider-pole.forms-slider-pole-r {right:0px;}

/* N�pov�da */
.forms-napoveda {color:#666666; font-size:11px;}
.forms-napoveda-nahore {padding-bottom:6px;} 
.forms-napoveda-dole {padding-top:6px;} 
.forms-napoveda-vyskakovaci {background-color:rgb(0,0,0); background-color:rgba(0,0,0,0.8); padding:8px 20px 8px 20px; font-size:12px; color:white; border-radius:5px; text-shadow:rgba(0,0,0,0.6) 0px 1px 0px; z-index:102;}
.forms-napoveda-vyskakovaci a {color:white; text-decoration:underline;}
.forms-napoveda-vyskakovaci .vyrazny {color:red}
.forms-napoveda-vyskakovaci .forms-napoveda-zobacek {display:block; width:9px; height:9px; position:absolute;}
.forms-napoveda-vyskakovaci-bottom .forms-napoveda-zobacek {left:20px; top:-9px; background:url("./zobacek-cerny-nahoru.png");}
.forms-napoveda-vyskakovaci-top .forms-napoveda-zobacek {left:20px; bottom:-9px; background:url("./zobacek-cerny-dolu.png");}
.forms-napoveda-vyskakovaci-right .forms-napoveda-zobacek {left:-9px; top:40%; background:url("./zobacek-cerny-doleva.png");}
.forms-napoveda-vyskakovaci-right-top .forms-napoveda-zobacek {left:-9px; top:5px; background:url("./zobacek-cerny-doleva.png");}

/* Password generator */
.pwgen {padding:8px; color:#333; border:solid 1px #666666; border-radius:4px; background-color:rgb(220,220,220); background-color:rgba(220,220,220,0.85); text-shadow:rgba(255,255,255,0.5) 0px 1px 0px; box-shadow:rgba(0,0,0,0.3) 0px 0px 4px; z-index:100;}
.forms-pwgenerator-nadpis {padding-bottom:8px; font-size:12px; font-weight:bold;}
.pwgen table {border:none; border-spacing:0px; font-size:10px; margin-bottom:5px;}
.forms-pwgenerator-input-mini {width:30px; background:white; border:solid 1px #666666; border-radius:3px; box-shadow:inset rgba(0,0,0,0.2) 0px 1px 2px;}
.forms-pwgenerator-button {margin-top:5px; cursor:pointer; border:solid 1px #333; border-radius:3px; background-color:#777; color:white; text-shadow:rgba(0,0,0,0.3) 0px 1px 0px; padding:4px 8px; cursor:pointer;}
.forms-pwgenerator-button:hover {background-color:#666;}
.forms-pwgenerator-heslo-popis {padding-top:10px; font-size:12px; font-weight:bold; padding-bottom:5px;}
.forms-pwgenerator-sila {font-size:10px; margin-bottom:6px; padding-top:3px;}
.forms-pwgenerator-output {font-size:16px; font-weight:bold; padding:5px 10px; background:white; border:solid 1px #666666; border-radius:3px; box-shadow:inset rgba(0,0,0,0.2) 0px 1px 2px; }
.forms-pwgenerator-sila-typ {}
.forms-pwgenerator-zavrit {position:absolute; right:0px; top:0px; width:16px; cursor:pointer; height:16px; background:url("./pwgen-close.png") no-repeat center center;}

/* Date a time picker*/
.ui-datepicker-calendar {border-spacing:0px;}
.ui-datepicker-calendar td {width:14.2%; padding:1px; height:20px !important; vertical-align:middle;}
.ui-datepicker-calendar td a {display:block;}
.ui_tpicker_hour_label, .ui_tpicker_minute_label {width:60px; float:left; clear:left;}
.ui_tpicker_hour, .ui_tpicker_minute  {width:100px; float:right;  margin:0px !important; margin-top:6px !important;}
.ui-datepicker-buttonpane {clear:both;}
.ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span {color: transparent;}

/* To check if css is loaded */
.forms-common-css-loaded {background:red; width:10px !important; height:10px  !important; padding:0px !important; border:none !important; position:absolute; left:-100px; top:-100px;}

/* GDPR stuff */
.forms-prvek .consent-checkbox__text { padding-top: 0px; }
.forms-prvek .consent-checkbox__box .pseudocheckbox-enabled { margin-bottom: 0px; }
.forms-prvek .consent-checkbox__box { align-items: center; }
.forms-prvek .consent-checkbox__chkb-wrap { line-height: 1px; }

@media (max-width: 500px) {
	table.forms-table, table.forms-sloupce {
		display: block;
	}

	table.forms-table>tbody>tr>td, table.forms-table>tr>td, table.forms-table>tbody>tr, table.forms-table>tbody,
	table.forms-sloupce>tbody>tr>td, table.forms-sloupce>tr>td, table.forms-sloupce>tbody>tr, table.forms-sloupce>tbody,
	td.forms-sloupec-1>table, td.forms-sloupec-1>table>tbody, td.forms-sloupec-1>table>tbody>tr, td.forms-sloupec-1>table>tbody>tr>td,
	td.forms-sloupec-2>table, td.forms-sloupec-2>table>tbody, td.forms-sloupec-2>table>tbody>tr, td.forms-sloupec-2>table>tbody>tr>td,
	td.forms-sloupec-3>table, td.forms-sloupec-3>table>tbody, td.forms-sloupec-3>table>tbody>tr, td.forms-sloupec-3>table>tbody>tr>td
	 {
		display: block;
		width: 100%;
	}

	table.forms-sloupce td[style] {
		width: 100% !important;
	}

	table.forms-table .forms-input[style], table.forms-table .forms-textarea[style] {
		max-width: 260px;
	}

	table.forms-table .forms-levy {
		width: 100% !important;
		text-align: left;
	}

	table.forms-table table.formsCheckboxTable {
		width: 100%;
		display: block;
	}

	table.forms-table table.formsCheckboxTable>tbody>tr>td, table.forms-table table.formsCheckboxTable>tbody, table.forms-table table.formsCheckboxTable>tbody>tr {
		display: block;
	}

	table.forms-table td.formsCheckboxTd {
		float: left;
	}

	table.forms-table table.formsCheckboxTable>tbody>tr, table.forms-table table.formsCheckboxTable>tr, table.forms-table td.formsCheckboxTd {
		clear: left;
	}	

	table.forms-table div.forms-invalid-popup, table.forms-table div.forms-warning-popup {
		position: static !important;
		margin: 5px 0;
		width: 100% !important;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}

	table.forms-table .forms-popup-zobacek {
		display: none; 
	}


}
